import { useState } from "react";
import { ContractKit } from "@wharfkit/contract";
import { APIClient } from "@wharfkit/antelope";
import { DAPP_CONTRACT, TESTNET, mainnetApiList, testnetApiList } from "../../data/config";

const apiList = TESTNET ? testnetApiList : mainnetApiList;

const contractKit = new ContractKit({
    client: new APIClient({ url: apiList[0] }),
  });

export const useShowVoteRewards = () => {
    
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);

    const getVoteRewards = async () => {
        setLoading(true);

        try {
            const contract = await contractKit.load(DAPP_CONTRACT);
        
            const result = await contract.readonly("showvoterwds", {
            });
            
            setAccounts(result);
            } catch (e) {
                console.log(`error checking for voting rewards: ${e}`);
            }   


        setLoading(false);
    }

    return [accounts, getVoteRewards, loading]
}
