import { ModalErrorCont, ModalSuccessCont } from "../../Styles2024";
import config from "../config.json";
import { error_svg, success_svg } from "../constants";

export const makeAction = (account, actionName, data, wharfSession) => {
    return {
      account: account,
      name: actionName,
      authorization: [wharfSession.permissionLevel],
      data: data,
    };
  };

export const submitTransaction = async (
    actions,
    successMessage,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
  
    try {
      const result = await session.transact(
        { actions: actions },
        config.txSettings
      );
      setTxIsLoading(true);
      setTxModalText(config.processingTxMessage);
      await new Promise((resolve) => {
        const timer = setTimeout(() => {
          setTxModalText(
            <span>
              <ModalSuccessCont>{success_svg}</ModalSuccessCont>
              {successMessage}
            </span>
          );
  
          setTxIsLoading(false);
          resolve();
        }, config.spinnerDuration);
        return () => clearTimeout(timer);
      });
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(
        <span>
          <ModalErrorCont>{error_svg}</ModalErrorCont>
          {e.message}
        </span>
      );
      setShowTxModal(true);
    }
  };