export const APP_NAME = "WaxFusion";
export const TESTNET = false;
export const DAPP_CONTRACT = "dapp.fusion";
export const TOKEN_CONTRACT = "token.fusion";
export const POL_CONTRACT = "pol.fusion";
export const DEFAULT_SLIPPAGE_TOLERANCE = 0; 

export const defaultTxSettings = {
  broadcast: true,
  expireSeconds: 300
}

export const bulkyTxSettings = {
  broadcast: true,
  expireSeconds: 3600
}

export const defaultSpinnerDuration = 1500;

export const mainnetWebsiteURL = "https://waxfusion.io";
export const testnetWebsiteURL = "https://test.waxfusion.io";
export const localWebsiteURL = "http://localhost:3000";

export const currentWebsiteURL = mainnetWebsiteURL;
export const currentUsername = localStorage.getItem('waxAccount');
export const currentWalletProvider = localStorage.getItem("walletProvider");

export const userSelectedAnApi = localStorage.getItem('userSelectedAnApi');
export const currentSelectedApi = localStorage.getItem('currentSelectedApi');

export const userSelectedALightApi = localStorage.getItem('userSelectedALightApi');
export const currentSelectedLightApi = localStorage.getItem('currentSelectedLightApi');

export const waxdaoApi = "https://waxdao-api.waxdaobp.io";


export const testnetChain = {
  chainId: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "test-api.waxdaobp.io",
      port: "443",
    },
  ],
};

export const mainnetChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "api.waxdaobp.io",
      port: "443",
    },
  ],
};

export const testnetApiList = [
  "https://test-api.waxdaobp.io",
  "https://waxtest.api.eosnation.io",
  "https://waxtest.api.eosnation.io",
];

export const mainnetApiList = [
  "https://api.waxdaobp.io",
  "https://api.waxsweden.org",
  "https://wax.api.eosnation.io",
  "https://api.hivebp.io",
  "https://api2.hivebp.io",
  "https://api3.hivebp.io",
  "https://wax.eosusa.io",
  "https://wax.eosdac.io"
];

export const mainnetLightApiList = [
  "https://light-api.waxdaobp.io",
  "https://wax.light-api.net",
  "https://lightapi-wax.tacocrypto.io",
  "https://light-api-wax-main.wecan.dev",
];

export const testnetLightApiList = [
  "https://testnet-lightapi.eosams.xeos.me"
]
