import { useState } from "react";
import { ContractKit } from "@wharfkit/contract";
import { APIClient } from "@wharfkit/antelope";
import { DAPP_CONTRACT, TESTNET, mainnetApiList, testnetApiList } from "../../data/config";

const apiList = TESTNET ? testnetApiList : mainnetApiList;

const contractKit = new ContractKit({
    client: new APIClient({ url: apiList[0] }),
  });

export const useShowRefunds = () => {
    
    const [loading, setLoading] = useState(true);
    const [isAvailable, setIsAvailable] = useState(false);

    const getRefunds = async () => {
        setLoading(true);

        try {
            const contract = await contractKit.load(DAPP_CONTRACT);
        
            const result = await contract.readonly("showrefunds", {
            });
            
            setIsAvailable(result);
            } catch (e) {
                console.log(`error checking for refunds: ${e}`);
            }   


        setLoading(false);
    }

    return [isAvailable, getRefunds, loading]
}
