import React, { useEffect, useState } from "react";
import { PageBody2024, PageWrapper2024 } from "../Styles2024";
import { useStateContext } from "../contexts/ContextProvider";
import {
  LogoPlusHeaderWrapper,
  MessageWrapper,
  StakeContainer,
} from "../FusionStyles";
import { LP_FARM_DURATION_SECONDS } from "../data/constants";
import { useShowExpiredCpu } from "../components/CustomHooks/useShowExpiredCpu";
import { makeAction, submitTransaction } from "../data/functions/transactions";
import { DAPP_CONTRACT } from "../data/config";
import TransactionModal from "../components/TransactionModal";
import { useShowRefunds } from "../components/CustomHooks/useShowRefunds";
import { useShowVoteRewards } from "../components/CustomHooks/useShowVoteRewards";
import { useGetLswaxPairs } from "../components/CustomHooks/useGetLswaxPairs";

const Incentivize = () => {
  const {
    fusionState,
    wharfSession,
    showTxModal,
    setShowTxModal,
    txModalText,
    setTxModalText,
    txIsLoading,
    setTxIsLoading,
    refresh,
    setRefresh
  } = useStateContext();

  const [epoch, getExpiredCpu, expiredCpuIsLoading] = useShowExpiredCpu();
  const [isAvailable, getRefunds, refundsAreLoading] = useShowRefunds();
  const [accounts, getVoteRewards, voteRewardsAreLoading] =
    useShowVoteRewards();

    const [pairs, debouncedGetPairs, pairsAreLoading] = useGetLswaxPairs();

    useEffect(() => {
        debouncedGetPairs();
    }, []);    

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getExpiredCpu();
      getRefunds();
      getVoteRewards();
    }

    return () => {
      isMounted = false;
    };
  }, [refresh]);

  return (
    <div>
      {showTxModal && (
        <TransactionModal
          setShowTxModal={setShowTxModal}
          txModalText={txModalText}
          txIsLoading={txIsLoading}
        />
      )}

      <PageWrapper2024>
        <PageBody2024>

          <StakeContainer>
            <LogoPlusHeaderWrapper>
              <h2>Add LP Incentives</h2>
            </LogoPlusHeaderWrapper>
           
              <MessageWrapper>
                You can use this page to create farms on Alcor, for any token paired against LSWAX.{" "}
                Simply select the token, fee tier, amount of LSWAX to reward, and length of the reward period.
              </MessageWrapper>

          </StakeContainer>

        </PageBody2024>
      </PageWrapper2024>
    </div>
  );
};

export default Incentivize;
