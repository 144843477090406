import { useState } from "react";
import { ContractKit } from "@wharfkit/contract";
import { APIClient } from "@wharfkit/antelope";
import { DAPP_CONTRACT, TESTNET, mainnetApiList, testnetApiList } from "../../data/config";

const apiList = TESTNET ? testnetApiList : mainnetApiList;

const contractKit = new ContractKit({
    client: new APIClient({ url: apiList[0] }),
  });

export const useShowExpiredCpu = () => {
    
    const [loading, setLoading] = useState(true);
    const [epoch, setEpoch] = useState(0);

    const getExpiredCpu = async () => {
        setLoading(true);

        try {
            const contract = await contractKit.load(DAPP_CONTRACT);
        
            const result = await contract.readonly("showexpcpu", {
                epoch_id: 0
            });
            
            setEpoch(result);
            } catch (e) {
            console.log(`error checking for expired CPU: ${e}`);
            }   


        setLoading(false);
    }

    return [epoch, getExpiredCpu, loading]
}
