import { useCallback, useRef, useState } from "react";
import { waxdaoApi } from "../../data/config";
import axios from "axios";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const useGetLswaxPairs = () => {
  const [pairs, setPairs] = useState([]);
  const [loading, setLoading] = useState(true);
  const lastUpdateRef = useRef(Date.now() - 1000);

  const getPairs = async () => {
    const now = Number(Date.now());

    if (now - lastUpdateRef.current < 1900) {
      return;
    }

    setLoading(true);

    const requestData = {};

    try {
      const res = await axios.post(`${waxdaoApi}/get-lswax-pairs`, requestData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (res.data) {
        setPairs(res.data);
        console.log(res.data);
      }
    } catch (error) {
      console.log(`Failed to fetch lswax pairs`);
    } finally {
      setLoading(false);
      lastUpdateRef.current = now;
    }
  };

  const debouncedGetPairs = useCallback(debounce(getPairs, 1000), []);
  return [pairs, debouncedGetPairs, loading];
};
